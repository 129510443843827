import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    height: 'auto',
    margin: '0 auto',
    border: `6px solid rgba(0,0,0,.2)`,
    boxShadow: `0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12)`,
  },
  imgWrapper: {
    textAlign: 'center',
  },
}))
const MediumImage = ({ img, alt, style, lazyHeight }) => {
  const classes = useStyles()
  const { xs, sm, md, lg, original, loading } = img
  return (
    <div className={classes.imgWrapper}>
      <picture>
        <source type='image/webp' media='(max-width:479px)' srcSet={xs.src} />
        <source type='image/webp' media='(max-width:759px)' srcSet={sm.src} />
        <source type='image/webp' media='(max-width:959px)' srcSet={md.src} />
        <source type='image/webp' media='(max-width:1279px)' srcSet={xs.src} />
        <source type='image/webp' media='(max-width:1439px)' srcSet={sm.src} />
        <source type='image/webp' media='(min-width:1440px)' srcSet={lg.src} />
        <img
          className={classes.root}
          loading={loading || 'lazy'}
          src={original.src}
          alt={alt || 'Erez Kaynak çelik hasır ve filtre elek imalatı'}
          style={style}
          title={alt || 'Erez Kaynak çelik hasır ve filtre elek imalatı'}
        />
      </picture>
    </div>
  )
}
export default memo(MediumImage)
