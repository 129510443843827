import React, { memo } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    // borderLeft: `4px solid ${theme.palette.secondary.main}`,
    // padding: '10px 0px 10px 15px',
    marginBottom: '40px',
  },
  contentHeader: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    // letterSpacing: '-3px',
    [theme.breakpoints.up('xl')]: {
      fontSize: '4em',
    },
    color: theme.palette.warning.main,
    letterSpacing: '0.025em',
  },
  contentDesc: {
    fontStyle: 'italic',
    fontSize: '1.3em',
    color: theme.palette.grey[600],
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.8em',
    },
  },
}))
const ContentHeader = ({ title, description }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {title && (
        <Typography className={classes.contentHeader} component='h1' variant='h3'>
          {title}
        </Typography>
      )}
      {description && (
        <Typography className={classes.contentDesc} component='span'>
          {description}
        </Typography>
      )}
    </Box>
  )
}
export default memo(ContentHeader)
